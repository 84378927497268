<template>
  <BasePageContent>
    <BasePageHeader
      class="px-3"
      sectiontitle="MANUFACTURER DETAIL"
      :title="!Util.isEmpty(Manufacturer) ? Manufacturer.manufacturer_name : ''"
      :description="!Util.isEmpty(Manufacturer) ? Manufacturer.email : ''"
    >
      <ul class="nk-block-tools g-1">
        <template v-if="!Util.isEmpty(Manufacturer)">
          <li class="nk-block-tools-opt">
            <router-link
              :to="{
                name: 'manufacturer-edit',
                params: { uuid: Manufacturer.uuid },
              }"
              class="btn w3-theme w3-hover-theme btn-dim btn-outline-light"
            >
              <em class="icon ni ni-edit"></em>
              <span>Edit</span>
            </router-link>
          </li>
          <li>
            <div class="drodown">
              <a
                href="#"
                class="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                data-toggle="dropdown"
              >
                <em class="icon ni ni-more-h"></em
              ></a>
              <div class="dropdown-menu dropdown-menu-right">
                <ul class="link-list-opt no-bdr">
                  <li>
                    <a title="Email Manufacturer" href="#">
                      <em class="dd-indc icon ni ni-mail-fill"></em>
                      <span>Email Manufacturer</span>
                    </a>
                  </li>
                  <!-- <li>
                    <a title="Create New Invoice" href="#">
                      <em class="dd-indc icon ni ni-file"></em>
                      <span>New Bill</span>
                    </a>
                  </li>
                  <li>
                    <a title="Create New Purchase Order" href="#">
                      <em class="dd-indc icon fas fa-file-alt"></em>
                      <span>New Purchase Order</span>
                    </a>
                  </li> -->
                  <li>
                    <a
                      title="Delete"
                      class="w3-text-red w3-hover-text-black"
                      @click.prevent="DeleteDetail(Manufacturer.uuid)"
                      href="#"
                    >
                      <em class="dd-indc icon ni ni-trash"></em>
                      <span> Delete Manufacturer</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <li class="nk-block-tools-opt">
            <router-link
              :to="{ name: 'manufacturers' }"
              class="px-2"
              title="Back"
            >
              <em class="icon ni ni-cross size36"></em>
            </router-link>
          </li>
        </template>
      </ul>
    </BasePageHeader>
    <div class="card-inner card-inner-md">
      <BaseIndicator
        text="Fetching manufacturer detail..."
        class="my-4"
        v-if="Util.fetching('getManufacturer')"
        :size="50"
      />
      <template v-else>
        <template v-if="!Util.isEmpty(Manufacturer)">
          <div
            class="
              w3-white
              py-0
              px-4
              w3-border-bottom
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="d-flex align-items-center justify-content-between">
              <button
                v-for="view in Views"
                :key="view"
                @click.prevent="SwitchView(view)"
                class="w3-button px-2 pt-3 w3-hover-transparent"
                :class="[
                  ActiveView === view
                    ? 'pb-1 w3-bottombar w3-border-theme'
                    : ' pb-2',
                ]"
              >
                <i
                  class="w3-text-theme w3-hover-text-theme"
                  :class="ViewsIcons[view]"
                />
                <span class="w3-hide-small ml-1">{{ ViewsNames[view] }}</span>
              </button>
            </div>
          </div>
          <div class="p-md-5 p-3">
            <div v-if="IsActiveView('Contact')" class="">
              <div class="p-1">
                <div class="row w3-border-bottom">
                  <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                    Manufacturer name
                  </div>
                  <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                    {{ Manufacturer.manufacturer_name ?? "-" }}
                  </div>
                </div>
                <div class="row w3-border-bottom">
                  <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                    First name
                  </div>
                  <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                    {{ Manufacturer.firstname ?? "-" }}
                  </div>
                </div>
                <div class="row w3-border-bottom">
                  <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                    Last name
                  </div>
                  <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                    {{ Manufacturer.lastname ?? "-" }}
                  </div>
                </div>
                <div class="row w3-border-bottom">
                  <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                    Telephone
                  </div>
                  <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                    {{ Manufacturer.telephone ?? "-" }}
                  </div>
                </div>
                <div class="row w3-border-bottom">
                  <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                    Email
                  </div>
                  <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                    {{ Manufacturer.email ?? "-" }}
                  </div>
                </div>
                <div class="row w3-border-bottom">
                  <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                    Website
                  </div>
                  <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                    {{ Manufacturer.website ?? "-" }}
                  </div>
                </div>
              </div>
              <div class="p-1">
                <div class="row w3-border-bottom">
                  <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                    Internal Note
                  </div>
                  <div
                    class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2"
                    v-html="Util.nl2br(Manufacturer.internal_note) ?? '-'"
                  ></div>
                </div>
              </div>
            </div>
            <div v-if="IsActiveView('Address')" class="">
              <div class="p-1">
                <div class="row w3-border-bottom">
                  <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                    Billing Address
                  </div>
                  <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                    {{ Manufacturer.address ?? "-" }}
                  </div>
                </div>
                <div class="row w3-border-bottom">
                  <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                    City
                  </div>
                  <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                    {{ Manufacturer.city ?? "-" }}
                  </div>
                </div>
                <div class="row w3-border-bottom">
                  <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                    State
                  </div>
                  <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                    {{ Manufacturer.state_name ?? "-" }}
                  </div>
                </div>
                <div class="row w3-border-bottom">
                  <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                    Zip/Postcode
                  </div>
                  <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                    {{ Manufacturer.postcode ?? "-" }}
                  </div>
                </div>
                <div class="row w3-border-bottom">
                  <div class="col-sm-4 w3-mobile px-2 pt-2 bold size13">
                    Country
                  </div>
                  <div class="col-sm-8 w3-mobile px-2 pt-md-2 pb-2">
                    {{ Manufacturer.country_name ?? "-" }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="IsActiveView('Bills')" class="">
              <div class="row">
                <div class="col-md-6">
                  <div class="p-1"></div>
                </div>
                <div class="col-md-6">
                  <div class="p-1"></div>
                </div>
              </div>
            </div>
            <div v-if="IsActiveView('PurchaseOrders')" class="">
              <div class="row">
                <div class="col-md-6">
                  <div class="p-1"></div>
                </div>
                <div class="col-md-6">
                  <div class="p-1"></div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-else class="p-5 w3-center">
          <img src="/images/item-not-found.png" class="mb-4" />
          <h4>Manufacturer not found</h4>
          <router-link
            :to="{ name: 'manufacturers' }"
            class="w3-button w3-white w3-border"
          >
            <span class="">Manage Manufacturers</span>
          </router-link>
        </div>
      </template>
    </div>
  </BasePageContent>
</template>

<script>
import { ref, toRef } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
import { Util } from "@/helpers/utilities";
// import ManufacturerUpdate from "@/components/ui/manufacturer/ManufacturerUpdate.vue";
// import Aside from "@/components/ui/Aside.vue";
export default {
  components: {
    // Aside,
    // ManufacturerUpdate
  },
  props: {
    manufacturer: {
      type: Object,
    },
    uuid: {
      type: String,
    },
  },
  emits: ["oncancel"],
  setup(props) {
    const store = useStore();
    console.log("props.uuid", props.uuid);
    const fetching = ref(true);
    const Manufacturer = ref({});
    const WAREHOUSE = toRef(props, "manufacturer");
    const GetManufacturer = async () => {
      Manufacturer.value = {};
      if (props.uuid) {
        const _manufacturer = await store.dispatch(
          "manufacturers/getManufacturer",
          props.uuid
        );
        Manufacturer.value = {
          ..._manufacturer,
        };
      } else if (WAREHOUSE.value) {
        Manufacturer.value = WAREHOUSE.value;
      } else {
        Manufacturer.value = {};
      }
      fetching.value = false;
    };
    GetManufacturer();
    // TAB VIEWS
    const Views = [
      "Contact",
      "Address",
      // "Bills", "PurchaseOrders"
    ];
    const ActionViews = ["Edit", "Delete"];
    const ViewsIcons = {
      Contact: "fas fa-user-tie",
      Address: "fas fa-map-marker-alt",
      Bills: "fas fa-file-invoice",
      PurchaseOrders: "fas fa-file-alt",
    };
    const ViewsNames = {
      Contact: "Overview",
      Address: "Address",
      Bills: "Bills",
      PurchaseOrders: "Purchase Orders",
    };

    const ActiveView = ref(Views[0]);
    const IsActiveView = (view) => (ActiveView.value === view ? true : false);
    const SwitchView = (view) => {
      const newViewIndex = Views.indexOf(view);
      if (ActiveView.value === Views[newViewIndex]) return false;
      ActiveView.value = Views[newViewIndex];
      return true;
    };

    const DeleteManufacturer = (uuid) => {
      console.log(uuid);
    };
    const OnCancel = () => {
      if (props.uuid) {
        // router.push({
        //   name: "manufacturers",
        // });
      } else {
        // emit("oncancel");
      }
    };
    //  END TAB VIEWS

    return {
      store,
      fetching,
      Manufacturer,
      OnCancel,
      Views,
      ActionViews,
      ViewsIcons,
      ViewsNames,
      IsActiveView,
      ActiveView,
      SwitchView,
      Util,
      DeleteManufacturer,
    };
  },
};
</script>